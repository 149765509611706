import { useTranslation } from 'react-i18next';
import { contactInfo } from '../../../globals/constants';
import Banner from '../../sections/common/banner';
import { bannerData } from '../../../globals/banner';

function ContactUsPage() {
  const { t } = useTranslation('contact');

  return (
    <>
      <Banner _data={bannerData.contact} />

      <div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer">
        <div className="container">
          <div className="section-content">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 m-b30">
                <div className="contact-info">
                  <div className="section-head left">
                    <div className="sx-head-s-title">{t('header.subtitle')}</div>
                    <div className="sx-head-l-title">
                      <h2 className="sx-title">{t('header.title')}</h2>
                    </div>
                  </div>
                  <div className="row sx-contact-form-icon-bx">
                    <div className="col-lg-6 col-md-6 m-b30">
                      <div className="sx-icon-bx-5">
                        <div className="sx-media">
                          <i className={t('contact.phone.icon')} />
                        </div>
                        <div className="sx-icon-bx-info">
                          <span className="sx-icon-bx-title-info">{t('contact.phone.title')}</span>
                          <a href={`tel:${contactInfo.phone}`} className="sx-icon-bx-title">
                            {contactInfo.phone}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 m-b30">
                      <div className="sx-icon-bx-5">
                        <div className="sx-media">
                          <i className={t('contact.email.icon')} />
                        </div>
                        <div className="sx-icon-bx-info">
                          <span className="sx-icon-bx-title-info">{t('contact.email.title')}</span>
                          <a href={`mailto:${contactInfo.email}`} className="sx-icon-bx-title">
                            {contactInfo.email}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 m-b30">
                      <div className="sx-icon-bx-5">
                        <div className="sx-media">
                          <i className={t('contact.location.icon')} />
                        </div>
                        <div className="sx-icon-bx-info">
                          <span className="sx-icon-bx-title-info">
                            {t('contact.location.title')}
                          </span>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${contactInfo.address}`}
                            className="sx-icon-bx-title"
                          >
                            {contactInfo.address}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 m-b30">
                      <div className="sx-icon-bx-5">
                        <div className="sx-media">
                          <i className={t('contact.hours.icon')} />
                        </div>
                        <div className="sx-icon-bx-info">
                          <span className="sx-icon-bx-title-info">{t('contact.hours.title')}</span>
                          <span className="sx-icon-bx-title">{t('contact.hours.weekday')}</span>
                          <span className="sx-icon-bx-title">{t('contact.hours.weekend')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 m-b30">
                <div className="contact-home1-right">
                  <div className="contact-home-1-form sx-bg-light">
                    <h4 className="sx-title">{t('form.title')}</h4>
                    <form
                      className="cons-contact-form2 form-transparent"
                      method="POST"
                      name="contact"
                    >
                      <input type="hidden" name="form-name" value="contact" />
                      <div className="input input-animate">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          required
                          placeholder={t('form.name')}
                        />
                        <span className="spin" />
                      </div>
                      <div className="input input-animate">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required
                          placeholder={t('form.email')}
                        />
                        <span className="spin" />
                      </div>
                      <div className="input input-animate">
                        <input
                          type="text"
                          name="phone"
                          id="Phone"
                          required
                          placeholder={t('form.phone')}
                        />
                        <span className="spin" />
                      </div>
                      <div className="input textarea input-animate">
                        <textarea
                          name="message"
                          id="message"
                          required
                          placeholder={t('form.message')}
                        />
                        <span className="spin" />
                      </div>
                      <div className="sx-btn-center text-center p-t10">
                        <button type="submit" className="site-button sx-btn-primary icon sx-btn-lg">
                          <i className="fa fa-long-arrow-right" />
                          {t('form.submit')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gmap-outline map-grayscle">
        <iframe
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=los%20angeles+(Techotony)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          title={t('banner.title')}
        />
      </div>
    </>
  );
}

export default ContactUsPage;
