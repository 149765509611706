import { NavLink } from 'react-router-dom';

export function SectionPricing1Inner() {
  return (
    <>
      <div className="container">
        {/* TITLE START */}
        <div className="section-head center">
          <div className="sx-head-s-title">Price Table</div>
          <div className="sx-head-l-title">
            <h2 className="sx-title">Our Best Pricing Table</h2>
          </div>
        </div>
        {/* TITLE END */}
        <div className="section-content">
          <div className="sx-p-table-section">
            {/*Block One*/}
            <div className="sx-p-table-bx-1 wow fadeInDown t-r-first" data-wow-duration="1000ms">
              <div className="sx-p-table-bx-1-top">
                <h3 className="sx-title">Price $/ Month</h3>
              </div>
              <div className="sx-p-table-bx-1-mid">
                <ul>
                  <li>25k Transactions for more</li>
                  <li>Get Low Pricing</li>
                  <li>Tooken White Labelled Farms</li>
                  <li>Advanced Route Optimization</li>
                  <li>Broadcast Feature</li>
                  <li>Branded Agent Deshboard</li>
                </ul>
              </div>
              <div className="sx-p-table-bx-1-bottom" />
            </div>
            {/*Block two*/}
            <div className="sx-p-table-bx-2 wow fadeInDown t-r-two" data-wow-duration="1000ms">
              <div className="sx-p-table-bx-2-top-title">
                <span>Atandard</span>
              </div>
              <div className="sx-p-table-bx-2-top">
                <h3 className="sx-price">
                  $00.99<span>/m</span>
                </h3>
              </div>
              <div className="sx-p-table-bx-2-mid">
                <ul>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>25k Transactions for more</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Get Low Pricing</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Tooken White Labelled Farms</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Advanced Route Optimization</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Broadcast Feature</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Branded Agent Deshboard</span>
                  </li>
                </ul>
              </div>
              <div className="sx-p-table-bx-2-bottom">
                <NavLink to="/faq" title="READ MORE" rel="bookmark" className="site-button icon">
                  <i className="fa  fa-long-arrow-right" />
                  Read More
                </NavLink>
              </div>
            </div>
            {/*Block three*/}
            <div
              className="sx-p-table-bx-2 active-plan wow fadeInDown t-r-three"
              data-wow-duration="1000ms"
            >
              <div className="sx-p-table-badge-wrap">
                <div className="sx-p-table-badge">
                  <span>Most Paopular</span>
                </div>
              </div>
              <div className="sx-p-table-bx-2-top-title">
                <span>Ultimate</span>
              </div>
              <div className="sx-p-table-bx-2-top">
                <h3 className="sx-price">
                  $19.99<span>/m</span>
                </h3>
              </div>
              <div className="sx-p-table-bx-2-mid">
                <ul>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>25k Transactions for more</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Get Low Pricing</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Tooken White Labelled Farms</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Advanced Route Optimization</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Broadcast Feature</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Branded Agent Deshboard</span>
                  </li>
                </ul>
              </div>
              <div className="sx-p-table-bx-2-bottom">
                <NavLink to="/faq" title="READ MORE" rel="bookmark" className="site-button icon">
                  <i className="fa  fa-long-arrow-right" />
                  Read More
                </NavLink>
              </div>
            </div>
            {/*Block four*/}
            <div className="sx-p-table-bx-2 wow fadeInDown t-r-four" data-wow-duration="1000ms">
              <div className="sx-p-table-bx-2-top-title">
                <span>Advanced</span>
              </div>
              <div className="sx-p-table-bx-2-top">
                <h3 className="sx-price">
                  $29.99<span>/m</span>
                </h3>
              </div>
              <div className="sx-p-table-bx-2-mid">
                <ul>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>25k Transactions for more</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Get Low Pricing</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Tooken White Labelled Farms</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Advanced Route Optimization</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Broadcast Feature</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Branded Agent Deshboard</span>
                  </li>
                </ul>
              </div>
              <div className="sx-p-table-bx-2-bottom">
                <NavLink to="/faq" title="READ MORE" rel="bookmark" className="site-button icon">
                  <i className="fa  fa-long-arrow-right" />
                  Read More
                </NavLink>
              </div>
            </div>
            {/*Block five*/}
            <div className="sx-p-table-bx-2 wow fadeInDown t-r-five" data-wow-duration="1000ms">
              <div className="sx-p-table-bx-2-top-title">
                <span>Professional</span>
              </div>
              <div className="sx-p-table-bx-2-top">
                <h3 className="sx-price">
                  $49.99<span>/m</span>
                </h3>
              </div>
              <div className="sx-p-table-bx-2-mid">
                <ul>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>25k Transactions for more</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Get Low Pricing</span>
                  </li>
                  <li>
                    <div className="sx-plan-enable">
                      <i className="feather-check" />
                    </div>
                    <span>Tooken White Labelled Farms</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Advanced Route Optimization</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Broadcast Feature</span>
                  </li>
                  <li>
                    <div className="sx-plan-disable">
                      <i className="feather-x" />
                    </div>
                    <span>Branded Agent Deshboard</span>
                  </li>
                </ul>
              </div>
              <div className="sx-p-table-bx-2-bottom">
                <NavLink to="/faq" title="READ MORE" rel="bookmark" className="site-button icon">
                  <i className="fa  fa-long-arrow-right" />
                  Read More
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SectionPricing1() {
  return (
    <>
      <div
        className="section-full p-t110 p-b110 sx-p-table-outer sx-bg-white wow fadeInDown"
        data-wow-duration="1000ms"
      >
        <SectionPricing1Inner />
      </div>
    </>
  );
}

export default SectionPricing1;
