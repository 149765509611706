import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SectionServices2() {
  const { t } = useTranslation('home');

  return (
    <>
      <div className="section-full p-t110 p-b80 sx-why-choose-outer sx-bg-light">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title">{t('services2.title.small')}</div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">{t('services2.title.large')}</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="owl-carousel sx-why-choose-carousel m-b30">
              {/*One block*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-monitor" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/e-commerce">
                        <h4 className="sx-tilte">{t('services2.items.ecommerce.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/e-commerce" className="sx-why-ch-category">
                        {t('services2.items.ecommerce.category')}
                      </NavLink>
                      <p>{t('services2.items.ecommerce.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/e-commerce">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/*One two*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-mouse-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/oms-wms">
                        <h4 className="sx-tilte">{t('services2.items.omsWms.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/oms-wms" className="sx-why-ch-category">
                        {t('services2.items.omsWms.category')}
                      </NavLink>
                      <p>{t('services2.items.omsWms.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/oms-wms">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/*One three*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-layers" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/crm-erp">
                        <h4 className="sx-tilte">{t('services2.items.erpCrm.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/crm-erp" className="sx-why-ch-category">
                        {t('services2.items.erpCrm.category')}
                      </NavLink>
                      <p>{t('services2.items.erpCrm.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/crm-erp">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/*One four*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-web-design" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/mis">
                        <h4 className="sx-tilte">{t('services2.items.mis.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/mis" className="sx-why-ch-category">
                        {t('services2.items.mis.category')}
                      </NavLink>
                      <p>{t('services2.items.mis.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/mis">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/*One five*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-monitor" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/enterprise-digitalization">
                        <h4 className="sx-tilte">{t('services2.items.enterpriseDigitalization.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/enterprise-digitalization" className="sx-why-ch-category">
                        {t('services2.items.enterpriseDigitalization.category')}
                      </NavLink>
                      <p>{t('services2.items.enterpriseDigitalization.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/enterprise-digitalization">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/*One six*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-mouse-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/mobile-app">
                        <h4 className="sx-tilte">{t('services2.items.mobileApp.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/mobile-app" className="sx-why-ch-category">
                        {t('services2.items.mobileApp.category')}
                      </NavLink>
                      <p>{t('services2.items.mobileApp.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/mobile-app">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/*One seven*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-layers" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/mini-program">
                        <h4 className="sx-tilte">{t('services2.items.miniProgram.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/mini-program" className="sx-why-ch-category">
                        {t('services2.items.miniProgram.category')}
                      </NavLink>
                      <p>{t('services2.items.miniProgram.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/mini-program">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/*One eight*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary">
                        <i className="flaticon-web-design" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/customize">
                        <h4 className="sx-tilte">{t('services2.items.customize.title')}</h4>
                      </NavLink>
                      <NavLink to="/services/customize" className="sx-why-ch-category">
                        {t('services2.items.customize.category')}
                      </NavLink>
                      <p>{t('services2.items.customize.description')}</p>
                      <NavLink className="sx-circle-btn" to="/services/customize">
                        <i className="fa fa-long-arrow-right" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionServices2;
