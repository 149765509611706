import { useTranslation } from 'react-i18next';
import { publicUrlFor } from '../../../../globals/constants';
import ItodoImage from '../../../elements/itodo-img';

function SectionOurInfo1() {
  const { t } = useTranslation('company');

  return (
    <>
      <div className="sx-mv-bx1-content-wrap">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="sx-about-bx1-content">
              <h2 className="sx-title">{t('whyChooseUs.title')}</h2>
              <span className="sx-title-2">{t('whyChooseUs.subtitle')}</span>
              <p>{t('whyChooseUs.description')}</p>
              <div className="progress">
                <div
                  className="progress-bar sx-bg-primary"
                  role="progressbar"
                  style={{ width: '95%' }}
                  aria-valuenow={95}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <span>{t('whyChooseUs.progress.success')}</span>
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar sx-bg-primary"
                  role="progressbar"
                  style={{ width: '97%' }}
                  aria-valuenow={97}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <span>{t('whyChooseUs.progress.satisfaction')}</span>
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar sx-bg-primary"
                  role="progressbar"
                  style={{ width: '99%' }}
                  aria-valuenow={99}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <span>{t('whyChooseUs.progress.delivery')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="sx-mv-bx1-media">
              <div className="sx-dot-bx-right">
                <ItodoImage src="images/about/dotted-pic.png" alt={t('whyChooseUs.images.dotted')} />
              </div>
              <div className="sx-mv-img-outer">
                <div className="sx-mv-img-section-2">
                  <ItodoImage src="images/about/p1-1.jpg" alt={t('whyChooseUs.images.team')} />
                </div>
                <div className="sx-mv-img-section-1-wrap">
                  <div className="sx-mv-video">
                    <a
                      href="https://www.youtube.com/watch?v=Set9GLHtAFU"
                      className="mfp-video circle"
                    >
                      <i className="flaticon-play" />
                    </a>
                  </div>
                  <div
                    className="sx-mv-img-section-1"
                    style={{
                      backgroundImage: `url(${publicUrlFor('images/about/right-pic.jpg')})`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionOurInfo1;
