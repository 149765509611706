import { useTranslation } from 'react-i18next';
import ItodoImage from '../../../elements/itodo-img';

function SectionAboutUs5() {
  const { t } = useTranslation('company');

  return (
    <>
      <div className="section-full p-t0 p-b80 sx-bg-white sx-about-bx1-outer">
        <div className="container g-5">
          <div className="sx-r-part-image-content-box">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-content">
                  <h2 className="sx-title">{t('aboutUs5.trackRecord.title')}</h2>
                  <p>{t('aboutUs5.trackRecord.content')}</p>
                  <div className="progress">
                    <div
                      className="progress-bar sx-bg-primary"
                      role="progressbar"
                      style={{ width: '97%' }}
                      aria-valuenow={75}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span>{t('aboutUs5.trackRecord.progress')}</span>
                    </div>
                  </div>
                  <h2 className="sx-title">{t('aboutUs5.technology.title')}</h2>
                  <p>{t('aboutUs5.technology.content')}</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-media">
                  <ItodoImage src="images/about/content-img-2.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
          <div className="sx-r-part-image-content-box">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-media">
                  <ItodoImage src="images/about/content-img-3.jpg" alt="image" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-content">
                  <h2 className="sx-title">{t('aboutUs5.team.title')}</h2>
                  <p>{t('aboutUs5.team.content')}</p>
                  <h2 className="sx-title">{t('aboutUs5.future.title')}</h2>
                  <p>{t('aboutUs5.future.content')}</p>
                  <div className="progress">
                    <div
                      className="progress-bar sx-bg-primary"
                      role="progressbar"
                      style={{ width: '75%' }}
                      aria-valuenow={75}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span>{t('aboutUs5.future.progress')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionAboutUs5;
