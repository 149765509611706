import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// 导入英文翻译文件
import enCommon from './locales/en/common.json';
import enHome from './locales/en/home.json';
import enCompany from './locales/en/company.json';
import enContact from './locales/en/contact.json';
import enItBlogs from './locales/en/it-blogs.json';
import enTermsOfUse from './locales/en/terms-of-use.json';

// 导入服务相关的英文翻译
import enServicesIndex from './locales/en/services/services.json';
import enServicesEcommerce from './locales/en/services/e-commerce.json';
import enServicesCrmErp from './locales/en/services/crm-erp.json';
import enServicesMiniProgram from './locales/en/services/mini-program.json';
import enServicesMobileApp from './locales/en/services/mobile-app.json';
import enServicesOmsWms from './locales/en/services/oms-wms.json';
import enServicesMis from './locales/en/services/mis.json';
import enServicesCustomize from './locales/en/services/customize.json';
import enServicesEnterpriseDigitalization from './locales/en/services/enterprise-digitalization.json';
import enPrivacyPolicy from './locales/en/privacy-policy.json';

// 导入中文翻译文件
import zhCommon from './locales/zh/common.json';
import zhHome from './locales/zh/home.json';
import zhCompany from './locales/zh/company.json';
import zhContact from './locales/zh/contact.json';
import zhItBlogs from './locales/zh/it-blogs.json';
import zhTermsOfUse from './locales/zh/terms-of-use.json';

// 导入服务相关的中文翻译
import zhServicesIndex from './locales/zh/services/services.json';
import zhServicesEcommerce from './locales/zh/services/e-commerce.json';
import zhServicesCrmErp from './locales/zh/services/crm-erp.json';
import zhServicesMiniProgram from './locales/zh/services/mini-program.json';
import zhServicesMobileApp from './locales/zh/services/mobile-app.json';
import zhServicesOmsWms from './locales/zh/services/oms-wms.json';
import zhServicesMis from './locales/zh/services/mis.json';
import zhServicesCustomize from './locales/zh/services/customize.json';
import zhServicesEnterpriseDigitalization from './locales/zh/services/enterprise-digitalization.json';
import zhPrivacyPolicy from './locales/zh/privacy-policy.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        common: enCommon,
        home: enHome,
        company: enCompany,
        contact: enContact,
        'it-blogs': enItBlogs,
        'services/index': enServicesIndex,
        'services/e-commerce': enServicesEcommerce,
        'services/crm-erp': enServicesCrmErp,
        'services/mini-program': enServicesMiniProgram,
        'services/mobile-app': enServicesMobileApp,
        'services/oms-wms': enServicesOmsWms,
        'services/mis': enServicesMis,
        'services/customize': enServicesCustomize,
        'services/enterprise-digitalization': enServicesEnterpriseDigitalization,
        'privacy-policy': enPrivacyPolicy,
        'terms-of-use': enTermsOfUse
      },
      zh: {
        common: zhCommon,
        home: zhHome,
        company: zhCompany,
        contact: zhContact,
        'it-blogs': zhItBlogs,
        'services/index': zhServicesIndex,
        'services/e-commerce': zhServicesEcommerce,
        'services/crm-erp': zhServicesCrmErp,
        'services/mini-program': zhServicesMiniProgram,
        'services/mobile-app': zhServicesMobileApp,
        'services/oms-wms': zhServicesOmsWms,
        'services/mis': zhServicesMis,
        'services/customize': zhServicesCustomize,
        'services/enterprise-digitalization': zhServicesEnterpriseDigitalization,
        'privacy-policy': zhPrivacyPolicy,
        'terms-of-use': zhTermsOfUse
      },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
