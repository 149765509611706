import ItodoImage from '../../../elements/itodo-img';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SectionClients2({ hideTitle }) {
  const { t } = useTranslation('home');

  const clientLogos = [
    'logo1.png', 'logo2.png', 'logo3.png', 'logo4.png',
    'logo5.png', 'logo6.png', 'logo7.png', 'logo8.png'
  ];

  return (
    <>
      <div className="section-full p-t110 p-b80 sx-client-logo-1-outer sx-bg-white">
        <div className="container">
          {/* TITLE START */}
          {(hideTitle === undefined || hideTitle === null || !hideTitle) && (
            <div className="section-head center max-900">
              <div className="sx-head-s-title">{t('clients2.title.small')}</div>
              <div className="sx-head-l-title">
                <h2 className="sx-title2">{t('clients2.title.large')}</h2>
              </div>
            </div>
          )}
          {/* TITLE END */}
          
          <div className="section-content">
            <div className="client-grid m-b30">
              <div className="row justify-content-center">
                {clientLogos.map((logo, index) => (
                  <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
                    <NavLink to={t('clients2.clients.link')} className="client-logo-pic">
                      <ItodoImage src={`images/client-logo/${logo}`} alt="" />
                      <div>
                        <span className="sx-circle-btn">
                          <i className="fa fa-long-arrow-right" />
                        </span>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionClients2;
