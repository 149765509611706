import { useTranslation } from 'react-i18next';
import Banner from '../../sections/common/banner';
import ItodoImage from '../../elements/itodo-img';
import { NavLink } from 'react-router-dom';
import { bannerData } from '../../../globals/banner';
import { useEffect } from 'react';
import { loadScript } from '../../../globals/constants';

function TermsOfUsePage() {
  const { t } = useTranslation('terms-of-use');

  useEffect(() => {
    loadScript('js/custom.js');
  });

  return (
    <>
      <Banner _data={bannerData.termsOfUse} />

      <div className="section-full p-t110 mobile-page-padding">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-icon-bx2-column sx-bg-light m-tb50">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="sx-icon-bx-6">
                    <div className="sx-media sx-text-secondry">
                      <i className="flaticon-category" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title">{t('meta.category.label')}</span>
                      <span className="sx-icon-bx-title-info">{t('meta.category.value')}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="sx-icon-bx-6">
                    <div className="sx-media sx-text-secondry">
                      <i className="flaticon-calendar" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title">{t('meta.lastUpdated.label')}</span>
                      <span className="sx-icon-bx-title-info">{t('meta.lastUpdated.value')}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="sx-icon-bx-6">
                    <div className="sx-media sx-text-secondry">
                      <i className="flaticon-leaf" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title">{t('meta.author.label')}</span>
                      <span className="sx-icon-bx-title-info">{t('meta.author.value')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-head left">
              <div className="sx-head-s-title">{t('content.subtitle')}</div>
              <div className="sx-head-l-title">
                <h2 className="sx-title">{t('content.title')}</h2>
              </div>
            </div>

            <div className="sx-our-service-info m-b30">
              <p>{t('content.intro.p1')}</p>
              <p>
                <b>{t('content.intro.p2')}</b>
              </p>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="sx-r-part-content">
                      <h2 className="sx-title">{t('content.consulting.title')}</h2>
                      <p>{t('content.consulting.description')}</p>
                      <h2 className="sx-title">{t('content.purchases.title')}</h2>
                      <p>{t('content.purchases.description')}</p>
                      <ul className="p-l20">
                        <li>
                          <b>Email:</b> {t('content.purchases.contact.email')}
                        </li>
                        <li>
                          <b>Phone:</b> {t('content.purchases.contact.phone')}
                        </li>
                        <li>
                          <b>WeChat:</b> {t('content.purchases.contact.wechat')}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="sx-r-part-media">
                      <ItodoImage src="images/terms-of-use/content-img1.jpg" alt={t('banner.image')} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.termination.title')}</h3>
                      <p>{t('content.termination.p1')}</p>
                      <p>{t('content.termination.p2')}</p>
                      <h3 className="sx-title">{t('content.updates.title')}</h3>
                      <p>{t('content.updates.description')}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="sx-r-part-media">
                      <ItodoImage src="images/terms-of-use/content-img2.jpg" alt={t('banner.image')} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.copyright.title')}</h3>
                      <p>{t('content.copyright.p1')}</p>
                      <p>{t('content.copyright.p2')}</p>
                      <p>{t('content.copyright.p3')}</p>
                      <p>{t('content.copyright.p4')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.miscellaneous.title')}</h3>
                      <p>{t('content.miscellaneous.description')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.acceptance.title')}</h3>
                      <p>{t('content.acceptance.p1')}</p>
                      <p>
                        {t('content.acceptance.contact')}{' '}
                        <NavLink to="/contact-us">{t('navigation.contact')}</NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfUsePage;
