import { NavLink } from 'react-router-dom';
import ItodoImage from '../../elements/itodo-img';
import { useState } from 'react';
import { loadScript, contactInfo } from '../../../globals/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Header1() {
  const [isActive, setIsActive] = useState(false);

  const { t, i18n } = useTranslation('common');

  function changeLanguage(lng) {
    i18n.changeLanguage(lng).then(() => {
      window.location.reload();
    });
  }

  function toggleNavClass() {
    setIsActive(!isActive);
  }

  useEffect(() => {
    loadScript('js/mobilenav.js');
  });

  return (
    <>
      <header
        className={
          'header-style1 site-header  mobile-sider-drawer-menu ' + (isActive ? 'active' : '')
        }
      >
        <div className="top-bar">
          <div className="container">
            <div className="d-flex justify-content-between  align-content-center">
              <div className="sx-topbar-left">
                <ul className="e-p-bx">
                  <li>{contactInfo.email}</li>
                  <li>
                    <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
                  </li>
                </ul>
              </div>
              <div className="sx-topbar-right">
                <div className="top-bar-nav">
                  <ul>
                    <li>
                      <NavLink to="/terms-of-use">{t('header.topBar.terms')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/privacy">{t('header.topBar.privacy')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact-us">{t('header.topBar.contact')}</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="sx-language-dd dropdown">
                  <div className="sl-nav-1">
                    {/* <div className="sx-language-first sx-title" id="FR"><b>FR</b></div> */}
                    <button
                      className="sl-nav-1-btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {i18n.language.toUpperCase()}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                      style={{ zIndex: 100 }}
                    >
                      <li onClick={() => changeLanguage('en')}>
                        {/* <div className="sl-flag">
                                                    <div className="c-name">
                                                        <ItodoImage src="images/flag/germany.png" alt="alt" />
                                                    </div>
                                                </div> */}
                        <span className="active">English</span>
                      </li>
                      <li onClick={() => changeLanguage('zh')}>
                        {/* <div className="sl-flag">
                                                    <div className="c-name">
                                                        <ItodoImage src="images/flag/italy.png" alt="alt" />
                                                    </div>
                                                </div> */}
                        <span className="active">中文</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar">
            <div className="container clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to="/index">
                    <ItodoImage src="images/Logo.png" alt="" />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                className="navbar-toggler collapsed"
                onClick={toggleNavClass}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* EXTRA NAV */}
              <div className="extra-nav">
                <div className="extra-cell">
                  {/* <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center">
                                        <li><a href="https://www.facebook.com/"><i className="feather-facebook" /> </a></li>
                                        <li><a href="https://twitter.com/"><i className="feather-twitter" /> </a></li>
                                        <li><a href="https://www.linkedin.com/"><i className="feather-linkedin" /> </a></li>
                                        <li><a href="https://www.instagram.com/"><i className="feather-instagram" /> </a></li>
                                    </ul> */}
                </div>
              </div>
              {/* EXTRA Nav */}
              {/* MAIN NAVIGATION */}
              <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                <ul className=" nav navbar-nav ">
                  <li>
                    <NavLink to="/index" className={({ isActive }) => (isActive ? 'active' : '')}>
                      {t('header.navigation.home')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about-us"
                      className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                      {t('header.navigation.company')}
                    </NavLink>
                    <ul className="sub-menu">
                      <li>
                        <NavLink
                          to="/about-us"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.company.aboutUs')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/faq" className={({ isActive }) => (isActive ? 'active' : '')}>
                          {t('header.company.faq')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/testimonials"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.company.testimonials')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/why-choose-us"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.company.whyChooseUs')}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to="/services"
                      className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                      {t('header.navigation.services')}
                    </NavLink>
                    <ul className="sub-menu">
                      <li>
                        <NavLink
                          to="/services/e-commerce"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.ecommerce')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services/oms-wms"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.omsWms')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services/crm-erp"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.crmErp')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services/mis"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.mis')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services/enterprise-digitalization"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.enterpriseDigitalization')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services/mobile-app"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.mobileApp')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services/mini-program"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.miniProgram')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/services/customize"
                          className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                          {t('header.services.customize')}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to="/contact-us"
                      className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                      {t('header.navigation.contact')}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header1;
