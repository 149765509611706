import ItodoImage from '../../../elements/itodo-img';
import { NavLink } from 'react-router-dom';

function SectionBlogSingle() {
  return (
    <>
      <div className="blog-single-space">
        {/* BLOG START */}
        <div className="sx-post-media sx-img-effect img-reflection m-b30">
          <NavLink to="/blogs/detail-with-sidebar">
            <ItodoImage src="images/blog/single/pic-1.jpg" alt="Image" />
          </NavLink>
        </div>
        <div className="single-blog-post blog-detail m-b30">
          <div className="sx-post-meta ">
            <ul>
              <li className="post-date">
                <NavLink to="/blogs/detail-with-sidebar">
                  <i className="fa fa-calendar" />
                  05 Jan 2023
                </NavLink>
              </li>
              <li className="post-author">
                <NavLink to="/blogs/detail-with-sidebar">
                  <i className="fa fa-comments" />0 Comments
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="sx-post-title ">
            <h2 className="post-title">
              Standard size of business agency kal Consulat Latest Management.
            </h2>
          </div>
          <div className="sx-post-text">
            <p>
              Pellentesque sed urna odio. Donec auctor nunc ac libero commodo volutpat. Nullam massa
              nulla, dignissim non pretium eu, variu Maecenas tortor arcu, efficitur sit amet
              eleifend vel, interdum id nulla.Etiam tristique non massa nec hendrerit. Cras lacinia
              varius euismod. Duis maximus tempor augue, eu blandit ex vehicula at. Maecenas
              accumsan dignissim diam pellentesque accumsan. lacus, quis vehicula nulla. Sed
              viverra, dui hendrerit lobortis pharetra, dui nulla facilisis risus, sed tincidunt
              orci mauris eu diam.lla euismod dapibus. Morbi sollicitudin tristique nibh, quis
              consequat nisi egestas vel. Lorem ipsum dolor sit amet, consectetur Donec maximus,
              augue nec feugiat convallis, est felis porta mauris, id finibus nulla justo at velit.
              Donec vestibulum pulvinar tortor, orci faucibus et. Maecenas tempor dolor turpis, ac
              tristique sapien rutrum ultrices. Quisque vitae est sed leo ornare bibendum. tempus
              sed fringilla eu, luctus sed nulla. Sed ac congue urna, vitae dictum augue. Aenean
              eget dictum nunc.
            </p>
            <p>
              Morbi quis ornare lorem, a malesuada lectus. Praesent rhoncus, nunc non molestie
              pretium, lectus enim facilisis arcu, lacinia mol dapibus dui. Ut vel commodo turpis,
              sed eleifend augue. Nullam mi erat, condimentum at mattis vel, auctor vitae magna.
              Nunc sit ligula. Quisque cursus elementum nisl, at varius sapien varius ac. Vestibulum
              ligula lectus, aliquam sollicitudin dui non, vulputate Suspendisse nec nibh nulla.
            </p>
            <h2 className="sx-post-detail-subtitle">Our Success Journey We can Provide.</h2>
            <p>
              Nullam id ultricies tellus. Nulla tempor, elit ac maximus accumsan, urna arcu congue
              neque, non venenatis leo erat sed massa. amet vestibulum. Cras tincidunt mauris id
              nisl rutrum rutrum. Duis tincidunt, nulla venenatis eleifend sollicitudin, lacus purus
              iacul nunc. Maecenas ultrices lorem sed nulla scelerisque ornare. Aliquam quis orci
              sit amet arcu tincidunt euismod ac eget odio. Viva interdum arcu massa, sed tristique
              odio luctus id. Nulla a porttitor felis, sit amet volutpat ante.
            </p>
            <p>
              Maecenas at dui ut leo efficitur tempus. Mauris elementum elit sed ex finibus aliquet.
              Donec turpis turpis, facilisis ac ante quis, pulvinar nibh ut risus euismod blandit.
              Proin cursus neque eget risus accumsan placerat. Quisque sapien lorem, maximus id
              turpi Maecenas ac dolor eget massa condimentum aliquet. Duis eget nisi facilisis eros
              sagittis semper sed ac ligula.
            </p>
            <div className="row sx-about-icon-bx1-column p-tb30">
              <div className="col-lg-6 col-md-6">
                <div className="sx-icon-bx-2">
                  <div className="sx-media">
                    <i className="flaticon-mouse-1" />
                  </div>
                  <div className="sx-icon-bx-info">
                    <span className="sx-icon-bx-title">Information technology</span>
                    <span className="sx-icon-bx-title-info">Consulting</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="sx-icon-bx-2">
                  <div className="sx-media">
                    <i className="flaticon-download" />
                  </div>
                  <div className="sx-icon-bx-info">
                    <span className="sx-icon-bx-title">Easy to edit process</span>
                    <span className="sx-icon-bx-title-info">IT Service</span>
                  </div>
                </div>
              </div>
            </div>
            <p>
              Proin mollis orci in nisi dictum, sed vulputate tellus porta. Donec sit amet nulla
              bibendum, mollis enim eu, molestie arcu. Aliquam tesque ac rutrum ut, semper quis
              neque. Phasellus convallis, elit vitae porttitor molestie, ante justo ultrices lorem,
              quis tristique libero. Vivamus vel iaculis magna, vel sollicitudin turpis. Aliquam
              erat volutpat. Donec ornare eu odio ut posuere. Suspendisse vestibulum scelerisque.
              Proin convallis elit in arcu tincidunt, in placerat eros faucibus. Sed volutpat,
              turpis a scelerisque convallis,volutpat velit.
            </p>
            <blockquote className="author-quote">
              <div className="sx-bq-media-wrap">
                <div className="sx-bq-media">
                  <i className="fa fa-quote-left" />
                  <ItodoImage src="images/bq-media.jpg" alt="" />
                </div>
              </div>
              <h4 className="sx-bq-title">Bentin Ali Benilmal</h4>
              <span>
                Phasellus ac consequat turpis, sit amet fermentum nulla. dignissim augue nunc.
                Praesent bibendu erat ac lectus lobortis curabitur ultrices justo Phasellus.
              </span>
            </blockquote>
            <p>
              Etiam tristique non massa nec hendrerit. Cras lacinia varius tellus eget euismod. Duis
              maximus tempor augue, eu blandit ex vehic accumsan dignissim diam pellentesque
              accumsan. Duis sed mattis lacus, quis vehicula nulla. Sed viverra, dui hendrerit
              lobortis ulla facilisis risus, sed tincidunt orci mauris eu diam. Fusce fringilla
              euismod dapibus. Morbi sollicitudin tristique nibh, quis conse egestas vel. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Donec maximus, augue nec feugiat
              convallis, est felis porta finibus nulla justo at velit. Donec vestibulum pulvinar
              tortor, sed vulputate orci faucibus et. Maecenas tempor dolor turpis, ac tristi rutrum
              ultrices. Quisque vitae est sed leo ornare bibendum. Donec ex nibh, tempus sed
              fringilla.
            </p>
            <div className="row sx-single-small-media">
              <div className="col-md-6 col-sm-4">
                <div className="sx-box m-b30">
                  <div className="sx-media">
                    <ItodoImage src="images/blog/portrait/pic1.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-4">
                <div className="sx-box m-b30">
                  <div className="sx-media">
                    <ItodoImage src="images/blog/portrait/pic2.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <h2 className="sx-post-detail-subtitle">IT Solution History.</h2>
            <p>
              Etiam tristique non massa nec hendrerit. Cras lacinia varius tellus eget euismod. Duis
              maximus tempor augue, eu blandit ex vehic accumsan dignissim diam pellentesque
              accumsan. Duis sed mattis lacus, quis vehicula nulla. Sed viverra, dui hendrerit
              lobortis facilisis risus, sed tincidunt orci mauris eu diam. Fusce fringilla euismod
              dapibus. Morbi sollicitudin tristique nibh, quis consequat vel. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Donec maximus, augue nec feugiat convallis, est
              felis porta mauris, justo at velit. Donec vestibulum pulvinar tortor, sed vulputate
              orci faucibus et. Maecenas tempor dolor turpis, ac tristique sapien Quisque vitae est
              sed leo ornare bibendum. Donec ex nibh, tempus sed fringilla.
            </p>
          </div>
          <div className="autor-post-tag-share">
            <div className="row">
              <div className="col-md-6">
                <div className="widget_tag_cloud single-post-tag">
                  <h6 className="tagcloud">Tags:</h6>
                  <div className="tagcloud">
                    <NavLink to="/services">Creative</NavLink>
                    <NavLink to="/services">Agency</NavLink>
                    <NavLink to="/services">Business</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="widget_social_inks sx-social-icons ">
                  <ul className="d-flex">
                    <li>
                      <a href="https://www.facebook.com/">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sx-blog-detail-author sx-bg-light">
            <div className="sx-media">
              <ItodoImage src="images/blog/single/avatar.jpg" alt="image" />
            </div>
            <div className="sx-content">
              <h3 className="sx-title">Monsur Rahman Lito</h3>
              <p>
                Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc.
                Praesent bibendum erat ac lectus molestie lobortis. Curabitur ultrices justo ac leo
                facilisis giographycal mathode maya.
              </p>
            </div>
          </div>
          <div className="s-post-pagination-control previous-next previous next">
            <div className="paging-left paging-item">
              <div className="paging-content">
                <NavLink className="paging-link" to="/blogs">
                  <i className="fa  fa-long-arrow-left" />
                </NavLink>
                <div className="paging-content-inner">
                  <NavLink className="paging-link2" to="/blogs">
                    Prev Services
                  </NavLink>
                  <h4 className="paging-title">
                    <NavLink to="/blogs">IT Solution Business</NavLink>
                  </h4>
                </div>
              </div>
            </div>
            <div className="paging-right paging-item">
              <div className="paging-content reverse">
                <NavLink className="paging-link" to="/blogs">
                  <i className="fa  fa-long-arrow-right" />
                </NavLink>
                <div className="paging-content-inner">
                  <NavLink className="paging-link2" to="/blogs">
                    Next Portfolio
                  </NavLink>
                  <h4 className="paging-title">
                    <NavLink to="/blogs">IT Solution Cources</NavLink>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionBlogSingle;
