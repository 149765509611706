import { useTranslation } from 'react-i18next';
import SectionVideo2 from '../../../sections/home/index2/section-video2';
import SectionWhyChooseUs4 from '../../../sections/company/about/section-why-choose-us4';
import SectionClients2 from '../../../sections/home/index2/section-clients2';
import SectionCounter1 from '../../../sections/home/index/section-counter1';
import ItodoImage from '../../../elements/itodo-img';
import { useEffect } from 'react';
import { loadScript } from '../../../../globals/constants';
import { bannerData } from '../../../../globals/banner';
import Banner from '../../../sections/common/banner';


function ServicesDetailPage() {
  const { t } = useTranslation('services/mobile-app');

  useEffect(() => {
    loadScript('js/custom.js');
  });

  return (
    <>
      <Banner _data={bannerData.mobileApp} />
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
        {/* <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage src="images/services/Mobile-App.jpg" alt={t('banner.image')} />
            <div className="sx-bnr-outline-text">
              <h1>{t('banner.title')}</h1>
            </div>
          </div>
        </div> */}
        <div className="container p-t100">
          <div className="section-content">
            <div className="sx-our-service-detail">
              <div className="section-head2">
                <div className="sx-head-s-title">{t('header.subtitle')}</div>
                <div className="sx-head-l-title">
                  <h2 className="sx-title2">{t('header.title')}</h2>
                </div>
              </div>
              <div className="sx-our-service-info m-b80">
                <p>{t('content.intro')}</p>
                <h5>{t('content.features.title')}</h5>
                <ul className="p-l20">
                  {t('content.features.list', { returnObjects: true }).map((feature, index) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: feature }}></li>
                  ))}
                </ul>
                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                  {t('steps', { returnObjects: true }).map((step, index) => (
                    <div key={index} className="col-lg-4 col-md-6 m-b30">
                      <div className="sx-icon-bx-2">
                        <div className="sx-media">
                          <i className={step.icon} />
                        </div>
                        <div className="sx-icon-bx-info">
                          <span className="sx-icon-bx-title">{step.title}</span>
                          <span>{step.description}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <p>{t('content.conclusion')}</p>
              </div>

              <SectionCounter1 />
            </div>
          </div>
        </div>
      </div>

      <SectionVideo2 />
      <SectionWhyChooseUs4 />
      <SectionClients2 />
    </>
  );
}

export default ServicesDetailPage;
