function SectionSocialIcons() {
  return (
    <>
      <div className="widget widget_social_icon">
        <h4 className="widget-title">Social Icon</h4>
        <div className="sx-social-icons ">
          <ul className="d-flex">
            <li>
              <a href="https://www.facebook.com/">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/">
                <i className="fa fa-linkedin" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/">
                <i className="fa fa-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default SectionSocialIcons;
