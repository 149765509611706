export const bannerData = {
  about: {
    background: 'images/banner/bnr-7.jpg',
    title: 'banner.breadcrumb.about',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  faq: {
    background: 'images/banner/bnr-4.jpg',
    title: 'banner.breadcrumb.faq',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  testimonials: {
    background: 'images/banner/bnr-3.jpg',
    title: 'banner.breadcrumb.testimonials',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  services: {
    background: 'images/banner/bnr-7.jpg',
    title: 'banner.breadcrumb.services',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  contact: {
    background: 'images/banner/bnr-6.jpg',
    title: 'banner.breadcrumb.contact',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  whychooseus: {
    background: 'images/banner/bnr-8.jpg',
    title: 'banner.breadcrumb.whyChooseUs',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  // 服务相关页面
  eCommerce: {
    background: 'images/services/E-Commerce.jpg',
    title: 'banner.breadcrumb.ecommerce',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  omsWms: {
    background: 'images/services/Logistics.jpg',
    title: 'banner.breadcrumb.omsWms',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  crmErp: {
    background: 'images/services/Erp-crm.jpg',
    title: 'banner.breadcrumb.crmErp',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  mis: {
    background: 'images/services/Mis.jpg',
    title: 'banner.breadcrumb.mis',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  mobileApp: {
    background: 'images/services/Mobile-app.jpg',
    title: 'banner.breadcrumb.mobileApp',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  miniProgram: {
    background: 'images/services/Mini-program.jpg',
    title: 'banner.breadcrumb.miniProgram',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  customize: {
    background: 'images/services/Customize-system-service.jpg',
    title: 'banner.breadcrumb.customize',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  enterpriseDigitalization: {
    background: 'images/services/Enterprise-digitalization.jpg',
    title: 'banner.breadcrumb.enterpriseDigitalization',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/services', label: 'banner.breadcrumb.services' }
      ]
    }
  },
  itBlogs: {
    background: 'images/banner/bnr-1.jpg',
    title: 'banner.breadcrumb.itBlogs',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  blogDetail: {
    background: 'images/banner/bnr-1.jpg',
    title: 'banner.breadcrumb.itBlogs',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/it-blogs', label: 'banner.breadcrumb.itBlogs' }
      ]
    }
  },
  privacyPolicy: {
    background: 'images/banner/bnr-1.jpg',
    title: 'banner.breadcrumb.privacyPolicy',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  termsOfUse: {
    background: 'images/banner/bnr-1.jpg',
    title: 'banner.breadcrumb.termsOfUse',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  portfolio1: {
    background: 'images/banner/bnr-1.jpg',
    title: 'banner.breadcrumb.portfolio1',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' }
      ]
    }
  },
  portfoliodetail: {
    background: 'images/banner/bnr-1.jpg',
    title: 'banner.breadcrumb.portfolioDetail',
    watermark: 'Techotony',
    breadcrumb: {
      links: [
        { to: '/index', label: 'banner.breadcrumb.home' },
        { to: '/portfolio', label: 'banner.breadcrumb.portfolio1' }
      ]
    }
  }
};
