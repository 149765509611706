import ItodoImage from '../../../elements/itodo-img';
import { publicUrlFor } from '../../../../globals/constants';
import SectionCounter1 from './section-counter1';
import { useTranslation } from 'react-i18next';

function SectionAboutCompany1() {
  const { t } = useTranslation('home');

  return (
    <>
      <div className="section-full p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-bx1-content-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-about-bx1-media">
                    <div className="sx-dot-bx">
                      <ItodoImage src="images/about/dotted-pic.png" alt="" />
                    </div>
                    <div className="sx-ab-img-outer">
                      <div className="sx-ab-img-section-1-wrap">
                        <div className="sx-ab-video">
                          <a
                            href="https://www.youtube.com/watch?v=Set9GLHtAFU"
                            className="mfp-video circle"
                          >
                            <i className="flaticon-play" />
                          </a>
                        </div>
                        <div
                          className="sx-ab-img-section-1"
                          style={{
                            backgroundImage: `url(${publicUrlFor('images/about/content-img-1-left.jpg')})`,
                          }}
                        />
                      </div>
                      <div className="sx-ab-img-section-2">
                        <ItodoImage src="images/about/content-img-1-right.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* 关于公司 */}
                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-about-bx1-content">
                    <h2 className="sx-title">{t('aboutCompany.title')}</h2>
                    <span className="sx-title-2">{t('aboutCompany.subtitle')}</span>
                    <p>{t('aboutCompany.description')}</p>
                    <div className="progress">
                      <div
                        className="progress-bar sx-bg-primary"
                        role="progressbar"
                        style={{ width: '75%' }}
                        aria-valuenow={75}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span>{t('aboutCompany.progressBar.text')}</span>
                      </div>
                    </div>
                    <div className="row sx-about-bx1-column">
                      <div className="col-lg-6 col-md-6">
                        <p>{t('aboutCompany.columns.left')}</p>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <p>{t('aboutCompany.columns.right')}</p>
                      </div>
                    </div>
                    <div className="row sx-about-icon-bx1-column">
                      <div className="col-lg-6 col-md-6">
                        <div className="sx-icon-bx-2">
                          <div className="sx-media">
                            <i className="flaticon-mouse" />
                          </div>
                          <div
                            className="sx-icon-bx-info"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <span className="sx-icon-bx-title">
                              {t('aboutCompany.services.itSolution')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="sx-icon-bx-2">
                          <div className="sx-media">
                            <i className="flaticon-download" />
                          </div>
                          <div
                            className="sx-icon-bx-info"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <span className="sx-icon-bx-title">
                              {t('aboutCompany.services.itConsultant')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* COUNTER START */}
            <SectionCounter1 />
            {/* COUNTER END */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionAboutCompany1;
