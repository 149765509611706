import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SectionServices1() {
  const { t } = useTranslation('home');

  return (
    <>
      <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title">{t('services.title.small')}</div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">{t('services.title.large')}</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="sx-our-services-bx">
              <div className="row">
                {/* Block one */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary">
                          <i className="flaticon-security" />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">{t('services.blocks.productEngineering.title')}</h4>
                        <p>{t('services.blocks.productEngineering.description')}</p>
                        <div className="sx-center-btn">
                          <NavLink to="/services" className="site-button-circle">
                            <i className="fa fa-long-arrow-right" />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Two */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary">
                          <i className="flaticon-coding" />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">{t('services.blocks.bigData.title')}</h4>
                        <p>{t('services.blocks.bigData.description')}</p>
                        <div className="sx-center-btn">
                          <NavLink to="/services" className="site-button-circle">
                            <i className="fa fa-long-arrow-right" />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Three */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary">
                          <i className="flaticon-smart-contracts" />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">{t('services.blocks.creativeSolutions.title')}</h4>
                        <p>{t('services.blocks.creativeSolutions.description')}</p>
                        <div className="sx-center-btn">
                          <NavLink to="/services" className="site-button-circle">
                            <i className="fa fa-long-arrow-right" />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionServices1;
