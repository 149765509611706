import ItodoImage from '../../../elements/itodo-img';
import { NavLink } from 'react-router-dom';
import SectionTestimonials2 from './section-testimonials2';
import { useTranslation } from 'react-i18next';

export function SectionWhyChooseUs2Inner() {
  const { t } = useTranslation('home');

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="sx-about-bx4-media">
            <div className="sx-ab4-img-outer">
              <div className="sx-ab4-media">
                <ItodoImage src="images/about/p3.jpg" alt="media" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="sx-about-bx4-content">
            <h2 className="sx-title">{t('whyChooseUs2.title')}</h2>
            <p>{t('whyChooseUs2.description')}</p>
            <ul className="sx-arrow-circle-checked">
              <li>{t('whyChooseUs2.features.consultation')}</li>
              <li>{t('whyChooseUs2.features.collaboration')}</li>
              <li>{t('whyChooseUs2.features.clientCentric')}</li>
            </ul>
            <NavLink 
              to={t('whyChooseUs2.button.link')} 
              className="site-button sx-btn-primary icon sx-btn-lg"
            >
              <i className="fa fa-long-arrow-right" />
              {t('whyChooseUs2.button.text')}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

function SectionWhyChooseUs2() {
  return (
    <>
      <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-bx4-content-wrap p-b70">
              <SectionWhyChooseUs2Inner />
            </div>
            {/*testimonial section */}
            <SectionTestimonials2 />
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionWhyChooseUs2;
