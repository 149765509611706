import { publicUrlFor } from '../../../../globals/constants';
import { NavLink } from 'react-router-dom';
import { register } from 'swiper/element/bundle';
import { useTranslation } from 'react-i18next';
register();

function SectionSlider1() {
  const { t } = useTranslation('home');

  return (
    <>
      <div className="sx-bnr-1-wrap-outer home-1-slider">
        <swiper-container
          loop="true"
          space-between="30"
          effect="fade"
          navigation-next-el=".swiper-button-next"
          navigation-perv-el=".swiper-button-prev"
          pagination-el=".swiper-pagination"
          pagination-clickable="true"
          autoplay-delay="7000"
          autoplay-disable-on-interaction="true"
          class="swiper-wrapper"
        >
          {/* Slide 1 */}
          <swiper-slide
            class="sx-bnr-1-wrap swiper-slide overlay-overlay"
            style={{
              backgroundImage: `url(${publicUrlFor('images/main-slider/slider1/slide2.jpg')})`,
            }}
          >
            <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
            <div className="container">
              <div className="sx-bnr-1-content">
                <span className="sx-bnr-1-small-title" data-swiper-parallax="-300">
                  {t('slider.slide1.smallTitle')}
                </span>
                <h2 className="sx-bnr-1-large-title">{t('slider.slide1.largeTitle')}</h2>
                <div className="sx-bnr-1-info">{t('slider.slide1.info')}</div>
                <div className="sx-bnr-readmore">
                  <NavLink to="/about-us" className="site-button sx-btn-primary icon">
                    <i className="fa fa-long-arrow-right" />
                    {t('slider.slide1.button')}
                  </NavLink>
                </div>
                <div className="sx-bnr-video">
                  <a href="https://www.youtube.com/watch?v=Set9GLHtAFU" className="mfp-video circle">
                    <i className="flaticon-play" />
                  </a>
                </div>
              </div>
            </div>
            <div className="sx-bnr-text-masking">
              <div className="light">
                <h1 className="sx-title">Techotony</h1>
              </div>
            </div>
          </swiper-slide>

          {/* Slide 2 */}
          <swiper-slide
            class="sx-bnr-1-wrap swiper-slide overlay-overlay"
            style={{
              backgroundImage: `url(${publicUrlFor('images/main-slider/slider1/slide1.jpg')})`,
            }}
          >
            <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
            <div className="container">
              <div className="sx-bnr-1-content">
                <span className="sx-bnr-1-small-title" data-swiper-parallax="-300">
                  {t('slider.slide2.smallTitle')}
                </span>
                <h2 className="sx-bnr-1-large-title">{t('slider.slide2.largeTitle')}</h2>
                <div className="sx-bnr-1-info">{t('slider.slide2.info')}</div>
                <div className="sx-bnr-readmore">
                  <NavLink to="/about-us" className="site-button sx-btn-primary icon">
                    <i className="fa fa-long-arrow-right" />
                    {t('slider.slide2.button')}
                  </NavLink>
                </div>
                <div className="sx-bnr-video">
                  <a href="https://www.youtube.com/watch?v=Set9GLHtAFU" className="mfp-video circle">
                    <i className="flaticon-play" />
                  </a>
                </div>
              </div>
            </div>
            <div className="sx-bnr-text-masking">
              <div className="light">
                <h1 className="animate-charcter">Techotony</h1>
              </div>
            </div>
          </swiper-slide>

          {/* Slide 3 */}
          <swiper-slide
            class="sx-bnr-1-wrap swiper-slide overlay-overlay"
            style={{
              backgroundImage: `url(${publicUrlFor('images/main-slider/slider1/slide3.jpg')})`,
            }}
          >
            <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
            <div className="container">
              <div className="sx-bnr-1-content">
                <span className="sx-bnr-1-small-title" data-swiper-parallax="-300">
                  {t('slider.slide3.smallTitle')}
                </span>
                <h2 className="sx-bnr-1-large-title">{t('slider.slide3.largeTitle')}</h2>
                <div className="sx-bnr-1-info">{t('slider.slide3.info')}</div>
                <div className="sx-bnr-readmore">
                  <NavLink to="/about-us" className="site-button sx-btn-primary icon">
                    <i className="fa fa-long-arrow-right" />
                    {t('slider.slide3.button')}
                  </NavLink>
                </div>
                <div className="sx-bnr-video">
                  <a href="https://www.youtube.com/watch?v=Set9GLHtAFU" className="mfp-video circle">
                    <i className="flaticon-play" />
                  </a>
                </div>
              </div>
            </div>
            <div className="sx-bnr-text-masking">
              <div className="light">
                <h1 className="sx-title">Techotony</h1>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>

        <div className="swiper-button-next" />
        <div className="swiper-button-prev" />
      </div>
    </>
  );
}

export default SectionSlider1;
