import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

function SectionCounter1() {
  const { t } = useTranslation('home');

  const counters = t('counter.items', { returnObjects: true });

  return (
    <>
      <div className="counter-blocks">
        <div className="row when-item-four">
          {counters.map((item, index) => (
            <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="sx-count">
                <h2 className="sx-title">
                  {item.prefix && <span className="sx-cont-before">{item.prefix}</span>}
                  <span className="counter">
                    <CountUp end={item.value} duration={item.duration} />
                  </span>
                  {item.suffix && <span className="sx-cont-after">{item.suffix}</span>}
                </h2>
                <div className="sx-count-info">{item.label}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionCounter1;
