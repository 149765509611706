import { SectionPricing1Inner } from '../../home/index/section-pricing1';

function SectionPricing2() {
  return (
    <>
      <div className="section-full  p-t100 p-b0 sx-bg-light sx-about-bx4-outer">
        <SectionPricing1Inner />
      </div>
    </>
  );
}

export default SectionPricing2;
