import { useTranslation } from 'react-i18next';
import { publicUrlFor } from '../../../../globals/constants';
import { NavLink } from 'react-router-dom';

function SectionWhyChooseUs1() {
  const { t } = useTranslation('home');

  return (
    <>
      <div
        className="section-full p-t110 p-b80 sx-why-chooseus-outer sx-bg-light bg-cover"
        style={{ backgroundImage: `url(${publicUrlFor('images/background/bg-1.jpg')})` }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title">{t('whyChooseUs.subtitle')}</div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">{t('whyChooseUs.title')}</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="sx-icon-bx-wrap">
              <div className="row">
                {t('whyChooseUs.services', { returnObjects: true }).map((service, index) => (
                  <div key={index} className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                    <NavLink to={service.link}>
                      <div className="sx-icon-bx-1">
                        <div className="sx-icon-box-wraper">
                          <div className="sx-icon-bx-icon">
                            <span className="sx-text-primary">
                              <i className={service.icon} />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="sx-tilte">{service.title}</h4>
                            <p>{service.description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionWhyChooseUs1;
