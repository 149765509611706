import { useTranslation } from 'react-i18next';
import ItodoImage from '../../../elements/itodo-img';
import { contactInfo } from '../../../../globals/constants';

function GeneralFAQContent() {
  const { t } = useTranslation('company');
  
  return (
    <>
      <div className="aon-faq-content">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="sx-acdn-style-1-section">
              <div className="accordion sx-acdn-style-1" id="sf-faq-accordion">
                {/*One*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ1"
                    aria-expanded="false"
                  >
                    {t('faqContent.general.customers.title')}
                  </button>
                  <div
                    id="FAQ1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body">
                      {t('faqContent.general.customers.content')}
                    </div>
                  </div>
                </div>
                {/*Two*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ2"
                    aria-expanded="false"
                  >
                    {t('faqContent.general.technology.title')}
                  </button>
                  <div
                    id="FAQ2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body">
                      <span>
                        {t('faqContent.general.technology.content.intro')}
                      </span>
                      <br />
                      <br />
                      <div>
                        <b>{t('faqContent.general.technology.content.languages.title')}</b>
                      </div>
                      <div>
                        {t('faqContent.general.technology.content.languages.content')}
                      </div>
                      <br />
                      <div>
                        <b>{t('faqContent.general.technology.content.cloud.title')}</b>
                      </div>
                      <div>
                        {t('faqContent.general.technology.content.cloud.content')}
                      </div>
                      <br />
                      <div>
                        <b>{t('faqContent.general.technology.content.database.title')}</b>
                      </div>
                      <div>{t('faqContent.general.technology.content.database.content')}</div>
                      <br />
                      <div>
                        {t('faqContent.general.technology.content.conclusion')}
                      </div>
                    </div>
                  </div>
                </div>
                {/*Three*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ3"
                    aria-expanded="false"
                  >
                    {t('faqContent.general.quality.title')}
                  </button>
                  <div
                    id="FAQ3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body"
                      dangerouslySetInnerHTML={{ 
                        __html: t('faqContent.general.quality.content') 
                      }}
                    />
                  </div>
                </div>
                {/*Four*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ4"
                    aria-expanded="false"
                  >
                    {t('faqContent.general.cost.title')}
                  </button>
                  <div
                    id="FAQ4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body"
                      dangerouslySetInnerHTML={{ 
                        __html: t('faqContent.general.cost.content') 
                      }}
                    />
                  </div>
                </div>
                {/*Five*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ5"
                    aria-expanded="false"
                  >
                    {t('faqContent.general.freeEstimate.title')}
                  </button>
                  <div
                    id="FAQ5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body">
                      {t('faqContent.general.freeEstimate.content')}
                    </div>
                  </div>
                </div>
                {/*Six*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ6"
                    aria-expanded="false"
                  >
                    {t('faqContent.general.maintenance.title')}
                  </button>
                  <div
                    id="FAQ6"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body">
                      {t('faqContent.general.maintenance.content')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="aon-faq-1-media">
              <ItodoImage src="images/faq-img-1.jpg" alt="#" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function HelpFAQContent() {
  const { t } = useTranslation('company');

  return (
    <>
      <div className="aon-faq-content">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="sx-acdn-style-1-section">
              <div className="accordion sx-acdn-style-1" id="sf-faq-accordion-1">
                {/*One*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ1-1"
                    aria-expanded="false"
                  >
                    {t('faqContent.help.intellectual.title')}
                  </button>
                  <div
                    id="FAQ1-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion-1"
                  >
                    <div className="accordion-body" 
                      dangerouslySetInnerHTML={{ 
                        __html: t('faqContent.help.intellectual.content') 
                      }} 
                    />
                  </div>
                </div>
                {/*Two - Contact*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ2-1"
                    aria-expanded="false"
                  >
                    {t('faqContent.help.contact.title')}
                  </button>
                  <div
                    id="FAQ2-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion-1"
                  >
                    <div className="accordion-body">
                      {t('faqContent.help.contact.content.intro')}
                    </div>
                    <ul>
                      <li dangerouslySetInnerHTML={{ 
                        __html: t('faqContent.help.contact.content.email') 
                      }} />
                      <br />
                      <li dangerouslySetInnerHTML={{ 
                        __html: t('faqContent.help.contact.content.phone') 
                      }} />
                      <br />
                      <li dangerouslySetInnerHTML={{ 
                        __html: t('faqContent.help.contact.content.wechat', { wechat: contactInfo.wechat }) 
                      }} />
                    </ul>
                  </div>
                </div>
                {/*Three*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ6"
                    aria-expanded="false"
                  >
                    {t('faqContent.help.timeline.title')}
                  </button>
                  <div
                    id="FAQ6"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body">
                      {t('faqContent.help.timeline.content')}
                    </div>
                  </div>
                </div>
                {/*Four*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ3"
                    aria-expanded="false"
                  >
                    {t('faqContent.help.projectJoin.title')}
                  </button>
                  <div
                    id="FAQ3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body">
                      {t('faqContent.help.projectJoin.content')}
                    </div>
                  </div>
                </div>
                {/*Five*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ5-1"
                    aria-expanded="false"
                  >
                    {t('faqContent.help.takeover.title')}
                  </button>
                  <div
                    id="FAQ5-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion-1"
                  >
                    <div className="accordion-body">
                      {t('faqContent.help.takeover.content')}
                    </div>
                  </div>
                </div>
                {/*Six*/}
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FAQ6-1"
                    aria-expanded="false"
                  >
                    {t('faqContent.help.onboarding.title')}
                  </button>
                  <div
                    id="FAQ6-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion-1"
                  >
                    <div className="accordion-body">
                      {t('faqContent.help.onboarding.content')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="aon-faq-1-media">
              <ItodoImage src="images/faq-img-2.jpg" alt="#" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SectionFAQContent() {
  return (
    <>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="day_1" role="tabpanel">
          <GeneralFAQContent />
        </div>
        <div className="tab-pane fade" id="day_2" role="tabpanel">
          <HelpFAQContent />
        </div>
      </div>
    </>
  );
}

export default SectionFAQContent;
