function SectionTestimonials2() {
  return (
    <>
      <div className="sx-testimonial-bx-2  m-b30">
        {/* TITLE START */}
        <div className="section-head center">
          <div className="sx-head-s-title">Our Testimonials</div>
          <div className="sx-head-l-title">
            <h2 className="sx-title">What Clients Say?</h2>
          </div>
        </div>
        {/* TITLE END */}
        <div className="owl-carousel testimonial-section-2 owl-btn-vertical-center">
          {/*One block*/}
          <div className="item">
            <div className="testimonial-1  hover-animation-1 sx-bg-white">
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <span className="testimonial-position">Founder Of Companey</span>
                  <h4 className="testimonial-name">Oliver Eliah Charlotte</h4>
                </div>
              </div>
              <div className="testimonial-text">
                <p>
                  Nullam cursus lacus insr veneni atis estas. libero lorem, vehicul ege sapien id,
                  faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou
                  fringil aliber.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </div>
                <ul className="sx-rating-st1">
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                </ul>
                <span className="sx-review-count">(5 Reviews)</span>
              </div>
            </div>
          </div>
          {/*One two*/}
          <div className="item">
            <div className="testimonial-1  hover-animation-1 sx-bg-white">
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <span className="testimonial-position">Founder Of Companey</span>
                  <h4 className="testimonial-name">Delilah Brooklyn</h4>
                </div>
              </div>
              <div className="testimonial-text">
                <p>
                  Nullam cursus lacus insr veneni atis estas. libero lorem, vehicul ege sapien id,
                  faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou
                  fringil aliber.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </div>
                <ul className="sx-rating-st1">
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                </ul>
                <span className="sx-review-count">(5 Reviews)</span>
              </div>
            </div>
          </div>
          {/*One three*/}
          <div className="item">
            <div className="testimonial-1  hover-animation-1 sx-bg-white">
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <span className="testimonial-position">Founder Of Companey</span>
                  <h4 className="testimonial-name">Oliver Eliah Charlotte</h4>
                </div>
              </div>
              <div className="testimonial-text">
                <p>
                  Nullam cursus lacus insr veneni atis estas. libero lorem, vehicul ege sapien id,
                  faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou
                  fringil aliber.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </div>
                <ul className="sx-rating-st1">
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                </ul>
                <span className="sx-review-count">(5 Reviews)</span>
              </div>
            </div>
          </div>
          {/*One four*/}
          <div className="item">
            <div className="testimonial-1  hover-animation-1 sx-bg-white">
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <span className="testimonial-position">Founder Of Companey</span>
                  <h4 className="testimonial-name">Delilah Brooklyn</h4>
                </div>
              </div>
              <div className="testimonial-text">
                <p>
                  Nullam cursus lacus insr veneni atis estas. libero lorem, vehicul ege sapien id,
                  faucibus vulp. Donec ut urna pretiumiss, ferment felis eget, cons exos. In etcou
                  fringil aliber.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </div>
                <ul className="sx-rating-st1">
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-star" />
                    </span>
                  </li>
                </ul>
                <span className="sx-review-count">(5 Reviews)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionTestimonials2;
