import { SectionWhyChooseUs2Inner } from '../index2/section-why-choose-us2';

function SectionWhyChooseUs3() {
  return (
    <>
      <div className="section-full  p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-bx4-content-wrap ">
              <SectionWhyChooseUs2Inner />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionWhyChooseUs3;
