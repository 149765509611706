import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ItodoImage from '../../elements/itodo-img';
import { contactInfo } from '../../../globals/constants';

function Footer() {
  const { t } = useTranslation('common');

  return (
    <>
      <footer className="site-footer footer-dark">
        <div className="sx-f-nl-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="sx-f-title">{t('footer.newsletter.title')}</h2>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="sx-nl-form">
                  <form method="POST" name="footer-contact">
                    <input type="hidden" name="form-name" value="footer-contact" />
                    <div className="sx-nl-form-inner">
                      <input
                        type="email"
                        className="sx-nl-form-input"
                        placeholder={t('footer.newsletter.placeholder')}
                        name="email"
                        id="footer-email"
                      />
                      <button type="submit" className="sx-nl-form-btn">
                        <i className="flaticon-back-left" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER BLOCKES START */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* ABOUT COMPANY */}
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <div className="widget widget_about">
                  <div className="logo-footer clearfix p-b15">
                    <NavLink to="/index" className="sx-text-primary">
                      <ItodoImage src="images/footer-logo.png" alt="#" />
                    </NavLink>
                  </div>
                  <p>{t('footer.contact.address')}</p>
                  <p>{t('footer.contact.wechat')}</p>
                  <p>{t('footer.contact.slogan')}</p>
                  <div className="widget_about-call-section">
                    <div className="sx-f-call-icon">
                      <i className="flaticon-telephone" />
                    </div>
                    <div className="sx-f-call-section">
                      <span>{t('footer.contact.support')}</span>
                      <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* EXPLORE LINKS */}
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 col">
                <div className="widget widget_services">
                  <h4 className="sx-f-title">{t('footer.links.explore.title')}</h4>
                  <ul>
                    <li>
                      <NavLink to="/services">{t('footer.links.explore.services')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about-us">{t('footer.links.explore.team')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/faq">{t('footer.links.explore.faq')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact-us">{t('footer.links.explore.contact')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/testimonials">{t('footer.links.explore.testimonials')}</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* USEFUL LINKS */}
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 col">
                <div className="widget widget_services">
                  <h4 className="sx-f-title">{t('footer.links.useful.title')}</h4>
                  <ul>
                    <li>
                      <NavLink to="/services/e-commerce">{t('footer.links.useful.ecommerce')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/oms-wms">{t('footer.links.useful.omsWms')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/crm-erp">{t('footer.links.useful.crmErp')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/mis">{t('footer.links.useful.mis')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/enterprise-digitalization">
                        {t('footer.links.useful.digitalization')}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* SOLUTIONS */}
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 col footer-col-3">
                <div className="widget widget_info">
                  <h4 className="sx-f-title">{t('footer.links.solutions.title')}</h4>
                  <ul>
                    <li>
                      <div className="widget-info-icon">
                        <i className="flaticon-phone" />
                      </div>
                      <div className="widget-info-detail">
                        <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
                        <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
                      </div>
                    </li>
                    <li>
                      <div className="widget-info-icon">
                        <i className="flaticon-email" />
                      </div>
                      <div className="widget-info-detail">
                        <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
                      </div>
                    </li>
                    <li>
                      <div className="widget-info-icon">
                        <i className="flaticon-maps-and-flags" />
                      </div>
                      <div className="widget-info-detail">
                        <a href={`https://www.google.com/maps/search/?api=1&query=${contactInfo.address}`}>
                          {contactInfo.address}
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sx-f-bottom-section">
          <div className="sx-f-copy">
            {t('footer.copyright')} <NavLink to="/index">{contactInfo.companyName}</NavLink> All rights reserved.
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
