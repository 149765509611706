import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { publicUrlFor } from '../../../globals/constants';

function Banner({ _data }) {
  const { t } = useTranslation('common');
  return (
    <>
      <div
        className="full-landing-image sx-bnr-inr overlay-wraper bg-top-center"
        style={{ backgroundImage: `url(${publicUrlFor(_data.background)})` }}
      >
        <div className="overlay-main bg-white opacity-0" />
        <div className="container">
          <div className="sx-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <span className="sx-bnr-sm-title">
                  {t('banner.subtitle')}
                </span>
                <h2 className="sx-title">
                  {t(_data.title)}
                </h2>
              </div>
              {/* BREADCRUMB ROW */}
              <div>
                <ul className="sx-breadcrumb breadcrumb-style-2">
                  {_data.breadcrumb.links.map((link, index) => (
                    <li key={index}>
                      <NavLink to={link.to}>
                        {t(link.label)}
                      </NavLink>
                    </li>
                  ))}
                  <li>{t(_data.title)}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="sx-bnr-outline-text">
          <h1>{_data.watermark}</h1>
        </div>
      </div>
    </>
  );
}

export default Banner;
