import { useTranslation } from 'react-i18next';
import Banner from '../../sections/common/banner';
import ItodoImage from '../../elements/itodo-img';
import { NavLink } from 'react-router-dom';
import { bannerData } from '../../../globals/banner';
import { useEffect } from 'react';
import { loadScript } from '../../../globals/constants';

function PrivacyPolicyPage() {
  const { t } = useTranslation('privacy-policy');

  useEffect(() => {
    loadScript('js/custom.js');
  });

  return (
    <>
      <Banner _data={bannerData.privacyPolicy} />

      <div className="section-full p-t110 mobile-page-padding">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-icon-bx2-column sx-bg-light m-tb50">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="sx-icon-bx-6">
                    <div className="sx-media sx-text-secondry">
                      <i className="flaticon-category" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title">{t('meta.category.label')}</span>
                      <span className="sx-icon-bx-title-info">{t('meta.category.value')}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="sx-icon-bx-6">
                    <div className="sx-media sx-text-secondry">
                      <i className="flaticon-calendar" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title">{t('meta.lastUpdated.label')}</span>
                      <span className="sx-icon-bx-title-info">{t('meta.lastUpdated.value')}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="sx-icon-bx-6">
                    <div className="sx-media sx-text-secondry">
                      <i className="flaticon-leaf" />
                    </div>
                    <div className="sx-icon-bx-info">
                      <span className="sx-icon-bx-title">{t('meta.author.label')}</span>
                      <span className="sx-icon-bx-title-info">{t('meta.author.value')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-head left">
              <div className="sx-head-s-title">{t('content.subtitle')}</div>
              <div className="sx-head-l-title">
                <h2 className="sx-title">{t('content.title')}</h2>
              </div>
            </div>

            <div className="sx-our-service-info m-b30">
              <p>{t('content.intro.p1')}</p>
              <p>{t('content.intro.p2')}</p>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="sx-r-part-content">
                      <h2 className="sx-title">{t('content.voluntaryInfo.title')}</h2>
                      <p>{t('content.voluntaryInfo.description')}</p>
                      <h2 className="sx-title">{t('content.contactData.title')}</h2>
                      <p>{t('content.contactData.description')}</p>
                      <p>{t('content.contactData.note')}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="sx-r-part-media">
                      <ItodoImage src="images/privacy-policy/content-img1.jpg" alt={t('banner.image')} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.rights.title')}</h3>
                      <p>{t('content.rights.description')}</p>
                      <ul className="p-l20">
                        {t('content.rights.list', { returnObjects: true }).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="sx-r-part-media">
                      <ItodoImage src="images/privacy-policy/content-img2.jpg" alt={t('banner.image')} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.cookies.title')}</h3>
                      <p>{t('content.cookies.intro')}</p>
                      <p>{t('content.cookies.types.intro')}</p>
                      <ul className="p-l20">
                        {t('content.cookies.types.list', { returnObjects: true }).map((cookie, index) => (
                          <li key={index}>
                            <b>{cookie.title}:</b> {cookie.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.security.title')}</h3>
                      <p>{t('content.security.description')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.retention.title')}</h3>
                      <p>{t('content.retention.description')}</p>
                      <ul className="p-l20">
                        {t('content.retention.list', { returnObjects: true }).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.changes.title')}</h3>
                      <p>{t('content.changes.description')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sx-r-part-image-content-box">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="sx-r-part-content">
                      <h3 className="sx-title">{t('content.acceptance.title')}</h3>
                      <p>{t('content.acceptance.description')}</p>
                      <p>
                        {t('content.acceptance.contact')}{' '}
                        <NavLink to="/contact-us">{t('navigation.contact')}</NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="s-post-pagination-control previous-next previous next">
            <div className="paging-left paging-item">
              <div className="paging-content">
                <NavLink className="paging-link" to="/services">
                  <i className="fa fa-long-arrow-left" />
                </NavLink>
                <div className="paging-content-inner">
                  <NavLink className="paging-link2" to="/services">
                    {t('navigation.prev.title')}
                  </NavLink>
                  <h4 className="paging-title">
                    <NavLink to="/services">{t('navigation.prev.description')}</NavLink>
                  </h4>
                </div>
              </div>
            </div>
            <div className="paging-right paging-item">
              <div className="paging-content reverse">
                <NavLink className="paging-link" to="/about-us">
                  <i className="fa fa-long-arrow-right" />
                </NavLink>
                <div className="paging-content-inner">
                  <NavLink className="paging-link2" to="/about-us">
                    {t('navigation.next.title')}
                  </NavLink>
                  <h4 className="paging-title">
                    <NavLink to="/about-us">{t('navigation.next.description')}</NavLink>
                  </h4>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyPage;
