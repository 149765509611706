import { useTranslation } from 'react-i18next';
import { bannerData } from '../../../globals/banner';
import Banner from '../../sections/common/banner';
import SectionClients2 from '../../sections/home/index2/section-clients2';

function TestimonialsPage() {
  const { t } = useTranslation('company');

  return (
    <>
      <Banner _data={bannerData.testimonials} />

      <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
        {/* TESTIMONIAL START */}
        <div className="container">
          <div className="section-content">
            {/* TITLE START */}
            <div className="section-head center">
              <div className="sx-head-s-title">{t('testimonials.title.small')}</div>
              <div className="sx-head-l-title">
                <h2 className="sx-title">{t('testimonials.title.large')}</h2>
              </div>
            </div>
            {/* TITLE END */}
            <div className="masonry-outer mfp-gallery news-grid clearfix row">
              {t('testimonials.clients', { returnObjects: true }).map((client, index) => (
                <div key={index} className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                  <div className="testimonial-3 hover-animation-1 sx-bg-light">
                    <div className="testimonial-detail">
                      <div className="testimonial-user-info">
                        <span className="testimonial-position">{client.position}</span>
                        <h4 className="testimonial-name">{client.name}</h4>
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>{client.content}</p>
                    </div>
                    <div className="testimonial-footer">
                      <div className="testimonial-quote sx-text-primary">
                        <i className="flaticon-quote" />
                      </div>
                      <ul className="sx-rating-st1">
                        {[...Array(5)].map((_, i) => (
                          <li key={i}>
                            <span>
                              <i className="fa fa-star" />
                            </span>
                          </li>
                        ))}
                      </ul>
                      {/* <span className="sx-review-count">(5 Reviews)</span> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* TESTIMONIAL END */}
      </div>

      <SectionClients2 />
    </>
  );
}

export default TestimonialsPage;
