import SectionOurInfo1 from './section-our-info1';

function SectionAboutCompany3() {
  return (
    <>
      <div className="section-full  p-t110 p-b80 bg-white sx-about-bx3-outer">
        <div className="container">
          <div className="section-content">
            {/* Info START */}
            <SectionOurInfo1 />
            {/* Info  END */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionAboutCompany3;
