import { useTranslation } from 'react-i18next';

function SectionTestimonials1() {
  const { t } = useTranslation('home');

  return (
    <>
      <div className="sx-testimonial-bx-1">
        <div className="owl-carousel testimonial-2-wrap owl-btn-vertical-center">
          {/*One block*/}
          <div className="item">
            <div className="testimonial-2  hover-animation-1">
              <div className="testimonial-text">
                <span className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </span>
                <p>{t('testimonials.items.item1.content')}</p>
              </div>
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <h4 className="testimonial-name">{t('testimonials.items.item1.name')}</h4>
                  <span className="testimonial-position">{t('testimonials.items.item1.position')}</span>
                </div>
              </div>
            </div>
          </div>
          {/*One two*/}
          <div className="item">
            <div className="testimonial-2  hover-animation-1">
              <div className="testimonial-text">
                <span className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </span>
                <p>{t('testimonials.items.item2.content')}</p>
              </div>
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <h4 className="testimonial-name">{t('testimonials.items.item2.name')}</h4>
                  <span className="testimonial-position">{t('testimonials.items.item2.position')}</span>
                </div>
              </div>
            </div>
          </div>
          {/*One three*/}
          <div className="item">
            <div className="testimonial-2  hover-animation-1">
              <div className="testimonial-text">
                <span className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </span>
                <p>{t('testimonials.items.item3.content')}</p>
              </div>
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <h4 className="testimonial-name">{t('testimonials.items.item3.name')}</h4>
                  <span className="testimonial-position">{t('testimonials.items.item3.position')}</span>
                </div>
              </div>
            </div>
          </div>
          {/*One four*/}
          <div className="item">
            <div className="testimonial-2  hover-animation-1">
              <div className="testimonial-text">
                <span className="testimonial-quote sx-text-primary">
                  <i className="flaticon-quote" />
                </span>
                <p>{t('testimonials.items.item4.content')}</p>
              </div>
              <div className="testimonial-detail">
                <div className="testimonial-user-info">
                  <h4 className="testimonial-name">{t('testimonials.items.item4.name')}</h4>
                  <span className="testimonial-position">{t('testimonials.items.item4.position')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionTestimonials1;
