import { useTranslation } from 'react-i18next';

export default function SectionFAQTabs() {
  const { t } = useTranslation('company');

  const tabs = [
    {
      id: 'day_1',
      active: true,
      translationKey: 'faqTabs.general.title'
    },
    {
      id: 'day_2',
      active: false,
      translationKey: 'faqTabs.help.title'
    }
  ];

  return (
    <>
      <ul className="nav nav-pills sx-tab-style-1" role="tablist">
        {tabs.map((tab) => (
          <li key={tab.id} className="nav-item">
            <div 
              className={`nav-link ${tab.active ? 'active' : ''}`}
              data-bs-toggle="pill" 
              data-bs-target={`#${tab.id}`}
            >
              <h5 className="tb-title">{t(tab.translationKey)}</h5>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
